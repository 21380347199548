:root {
  /* Dégradé radial, allant d’un gris profond (#1f2937) à du noir */
  --bg-gradient: radial-gradient(circle at 50% 50%, #1f2937 0%, #000000 100%) !important;
  --text-color: #fff !important;
  --primary-color: #d4af37 !important;
  --hover-color: #660000 !important;
}

/* Section YouTube */
.youtube-section {
  background: var(--bg-gradient) !important;
  color: var(--text-color) !important;
  padding: 4rem 2rem !important;
  text-align: center !important;
  overflow: hidden !important;
  border-bottom: 2px solid var(--primary-color) !important;
  /* Léger halo doré autour de la section */
  box-shadow: inset 0 0 15px rgba(212, 175, 55, 0.2);
  position: relative;
}

/* Titre de la section */
.youtube-section-title {
  font-family: 'Cinzel', serif !important;
  color: var(--primary-color) !important;
  font-size: 2.8rem !important;
  margin-bottom: 1.5rem !important;
  position: relative !important;
  /* Légère lueur dorée derrière le texte */
  text-shadow: 0 0 8px rgba(212, 175, 55, 0.4);
}

.youtube-section-title::after {
  content: '' !important;
  width: 60px !important;
  height: 3px !important;
  background: var(--primary-color) !important;
  display: block !important;
  margin: 0.5rem auto 0 !important;
}

/* Introduction */
.youtube-introduction {
  font-size: 1.2rem !important;
  margin: 0 auto 2rem !important;
  padding: 0 15% !important;
  line-height: 1.8 !important;
  opacity: 0.9 !important; /* Légèrement plus lisible que 0.85 */
}

/* Container des cartes vidéos */
.youtube-videos-container {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
  gap: 2rem !important;
  /* On commence caché, l’animation d’apparition est gérée plus bas */
}

/* Chaque carte vidéo */
.youtube-video-card {
  /* Dégradé sombre et subtil liseré doré */
  background: linear-gradient(135deg, #2b2b2b 0%, #3a3a3a 100%) !important;
  border: 1px solid rgba(212, 175, 55, 0.3) !important;
  border-radius: 12px !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3) !important;
  transition: 
    transform 0.3s ease, 
    box-shadow 0.3s ease, 
    opacity 0.5s ease-out, 
    transform 0.5s ease-out !important;
  
  /* État initial (invisible, légèrement décalé) pour l’animation d’apparition */
  opacity: 0;
  transform: translateY(20px);
}

/* Hover sur la carte : effet d'élévation plus prononcé */
.youtube-video-card:hover {
  transform: translateY(-10px) !important;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4) !important;
}

/* Iframe vidéo */
.youtube-video-card iframe {
  width: 100% !important;
  height: 225px !important;
  border: none !important;
  border-radius: 12px 12px 0 0 !important;
}

/* Titre de la vidéo */
.youtube-video-card h3 {
  font-family: 'Playfair Display', serif !important;
  font-size: 1.2rem !important;
  color: #ffffff !important;
  /* Dégradé subtilement plus clair au milieu */
  background: linear-gradient(135deg, var(--hover-color) 0%, #8b0000 100%) !important;
  padding: 1rem !important;
  border-radius: 0 0 12px 12px !important;
  margin: 0 !important;
  text-align: center !important;
}

/* Apparition progressive : quand la classe visible est appliquée */
.youtube-videos-container.visible .youtube-video-card {
  opacity: 1;
  transform: translateY(0);
}

/* Délais d’apparition progressifs (stagger) */
.youtube-videos-container.visible .youtube-video-card:nth-child(1) {
  transition-delay: 0s;
}
.youtube-videos-container.visible .youtube-video-card:nth-child(2) {
  transition-delay: 0.2s;
}
.youtube-videos-container.visible .youtube-video-card:nth-child(3) {
  transition-delay: 0.4s;
}
.youtube-videos-container.visible .youtube-video-card:nth-child(4) {
  transition-delay: 0.6s;
}

/* Responsive */
@media (max-width: 768px) {
  .youtube-introduction {
    padding: 0 10% !important;
  }
  .youtube-video-card iframe {
    height: 200px !important;
  }
  .youtube-section-title {
    font-size: 2.2rem !important;
  }
}

@media (max-width: 480px) {
  .youtube-introduction {
    padding: 0 5% !important;
    font-size: 1rem !important;
  }
  .youtube-section-title {
    font-size: 1.8rem !important;
  }
  .youtube-video-card iframe {
    height: 180px !important;
  }
  .youtube-video-card h3 {
    font-size: 1rem !important;
    padding: 0.8rem !important;
  }
}
