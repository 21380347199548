@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap');

:root {
  --bg-gradient: linear-gradient(135deg, #1f2937 0%, #2d3748 60%, #1f2937 100%);
  --text-color: #fff !important;
  --primary-color: #d4af37 !important;
  --hover-color: #920b0b !important;
  --card-bg: #2d3748 !important;
  --light-text-color: #ddd !important;
  --shadow-color: rgba(0, 0, 0, 0.3) !important;
  --author-bg: rgba(0, 0, 0, 0.7) !important;
}

/* Conteneur principal */
.admin-dashboard-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: var(--bg-gradient) !important;
  height: 100vh !important;
  padding-top: 120px !important;
}

/* Carte du tableau de bord */
.admin-dashboard-card {
  background: var(--card-bg) !important;
  border: none !important;
  border-radius: 12px !important;
  box-shadow: 0 4px 8px var(--shadow-color) !important;
  color: var(--text-color) !important;
  width: 95% !important;
  max-width: 1200px !important;
  overflow: hidden !important;
  margin-bottom: 20px !important;
}

.admin-dashboard-card .card-body {
  padding: 20px !important;
}

.admin-dashboard-title {
  color: var(--primary-color) !important;
  font-family: 'Playfair Display', serif !important;
  text-align: center !important;
  margin-bottom: 20px !important;
}

/* Tableau des utilisateurs */
.admin-dashboard-table {
  width: 100% !important;
  background-color: var(--card-bg) !important;
  border-collapse: collapse !important;
  margin: 25px 0 !important;
  font-size: 0.9em !important;
  min-width: 400px !important;
  box-shadow: 0 0 20px var(--shadow-color) !important;
  border-radius: 8px !important;
  overflow: hidden !important;
}

.admin-dashboard-table thead tr {
  background-color: var(--hover-color) !important;
  color: var(--text-color) !important;
  text-align: left !important;
}

.admin-dashboard-table th,
.admin-dashboard-table td {
  padding: 12px 15px !important;
  text-align: center !important;
}

.admin-dashboard-table tbody tr {
  border-bottom: 1px solid #444 !important;
}

.admin-dashboard-table tbody tr:nth-of-type(even) {
  background-color: #333 !important;
}

.admin-dashboard-table tbody tr:hover {
  background-color: #444 !important;
  color: var(--primary-color) !important;
  cursor: pointer !important;
}

.admin-dashboard-table tbody tr:last-of-type {
  border-bottom: 2px solid var(--hover-color) !important;
}

/* Boutons */
.admin-dashboard-button-container {
  text-align: center !important;
  margin-top: 15px !important;
}

.admin-dashboard-edit-button,
.admin-dashboard-delete-button {
  font-size: 0.9em !important;
  border: none !important;
  border-radius: 5px !important;
  padding: 10px 20px !important;
  cursor: pointer !important;
  margin: 0 5px !important;
  transition: transform 0.2s, background-color 0.3s !important;
}

.admin-dashboard-edit-button {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.admin-dashboard-delete-button {
  background-color: var(--hover-color) !important;
  color: white !important;
}

.admin-dashboard-edit-button:hover {
  background-color: #b8860b !important;
  transform: scale(1.05) !important;
}

.admin-dashboard-delete-button:hover {
  background-color: #a50f0f !important;
  transform: scale(1.05) !important;
}

/* Modal */
.modal-content {
  background-color: var(--card-bg) !important;
  color: var(--text-color) !important;
  border: none !important;
  border-radius: 12px !important;
}

.modal-header,
.modal-body,
.modal-footer {
  border-bottom: 1px solid var(--hover-color) !important;
}

.modal-title {
  color: var(--primary-color) !important;
  font-family: 'Playfair Display', serif !important;
}

/* Formulaire dans le Modal */
.form-select {
  background-color: var(--card-bg) !important;
  border: 1px solid var(--hover-color) !important;
  color: var(--primary-color) !important;
  padding: 10px !important;
}

.form-select:focus {
  background-color: #444 !important;
  border-color: var(--primary-color) !important;
  box-shadow: none !important;
}

/* Pagination */
.pagination {
  display: flex !important;
  justify-content: center !important;
  margin-top: 20px !important;
}

.pagination .page-item .page-link {
  background-color: var(--card-bg) !important;
  border: 1px solid var(--hover-color) !important;
  color: var(--primary-color) !important;
  transition: background-color 0.3s ease, color 0.3s ease !important;
  border-radius: 5px !important;
  padding: 10px 15px !important;
}

.pagination .page-item.active .page-link {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.pagination .page-item:hover .page-link {
  background-color: var(--hover-color) !important;
  color: #fff !important;
}

/* Spinner */
.admin-dashboard-spinner {
  width: 50px !important;
  height: 50px !important;
  border: 5px solid rgba(255, 255, 255, 0.3) !important;
  border-top-color: var(--primary-color) !important;
  border-radius: 50% !important;
  animation: spin 1s linear infinite !important;
}

@keyframes spin {
  to {
    transform: rotate(360deg) !important;
  }
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .admin-dashboard-table {
    font-size: 0.85em !important;
  }

  .admin-dashboard-edit-button,
  .admin-dashboard-delete-button {
    padding: 8px 12px !important;
  }

  .pagination .page-item .page-link {
    padding: 8px 12px !important;
  }
}

@media (max-width: 768px) {
  .admin-dashboard-card {
    margin: 10px !important;
  }

  .admin-dashboard-table {
    font-size: 0.8em !important;
  }
}

@media (max-width: 576px) {
  .admin-dashboard-table th,
  .admin-dashboard-table td {
    font-size: 0.75em !important;
  }

  .admin-dashboard-edit-button,
  .admin-dashboard-delete-button {
    font-size: 0.75em !important;
    padding: 6px 10px !important;
  }

  .pagination .page-item .page-link {
    font-size: 0.8em !important;
    padding: 6px 10px !important;
  }
}
