/* src/components/PerformanceDetail.css */
.performance-detail-container {
    max-width: 800px;
    margin: 20px auto;
    background-color: #2c2c2c;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    color: #fff;
}

.performance-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.performance-date-location {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: bold;
    font-size: 1.2em;
    margin-top: 10px;
}

.detail-section {
    margin-top: 20px;
    padding: 10px;
    background-color: #333;
    border-left: 5px solid #8b0000;
    color: #fff;
}

.detail-section h3 {
    color: #ffcccb;
    border-bottom: 1px solid #ffcccb;
}

.performance-share {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
    padding: 10px;
    background-color: #2c2c2c;
    border-radius: 10px;
}

.share-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    background-color: transparent;
    color: #ffcccb;
    transition: transform 0.2s ease, color 0.3s ease;
}

.share-button:hover {
    transform: scale(1.1);
    color: #fff;
}

.back-button {
    font-size: 16px;
    background-color: #660000;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.back-button:hover {
    background-color: #ffcccb;
    color: #660000;
    transform: translateY(-2px);
}

.comments-section {
    background-color: #202020;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.comments-section form {
    display: flex;
    flex-direction: column;
}

.comments-section textarea {
    background-color: #333;
    color: #fff;
    border: 1px solid #8b0000;
    margin-bottom: 10px;
    padding: 10px;
}

.comments-section button {
    align-self: end;
    background-color: #8b0000;
    color: #fff;
    padding: 5px 15px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.comments-section button:hover {
    background-color: #ffcccb;
    color: #660000;
}

.comments-list .card {
    background-color: #333;
    border: none;
    margin-bottom: 10px;
}

.comments-list .card-body {
    color: #fff;
}

.card-text {
    color: #fff;
}

.faArrowLeft {
    margin-right: 5px;
}

@media (max-width: 768px) {
    .performance-detail-container {
        margin: 10px;
        padding: 10px;
    }

    .performance-detail-container h2.performance-title {
        font-size: 1.5em;
    }

    .detail-section p {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .performance-detail-container {
        border-radius: 5px;
    }

    .performance-date-location {
        flex-direction: column;
        align-items: center;
    }

    .performance-share {
        flex-direction: column;
        align-items: center;
    }

    .share-button {
        margin: 5px 0;
    }
}

.comment-card {
    background-color: #2c2c2c;
    color: #fff;
}

.comment-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #660000;
    /* Couleur d'arrière-plan de l'en-tête */
    color: #fff;
    /* Couleur du texte de l'en-tête */
}

.comment-user {
    font-weight: bold;
    font-size: 1.1em;
    /* Taille du texte pour le pseudo */
    color: #ffcccb;
}

.comment-date {
    font-size: 0.9em;
    /* Taille du texte pour la date */
    color: #ddd;
    /* Couleur du texte pour la date */
}

.delete-comment-icon {
    color: #ffcccb;
    /* Couleur de l'icône de suppression */
    cursor: pointer;
    font-size: 1.5em;
    /* Taille de l'icône */
    transition: color 0.3s ease;
}

.delete-comment-icon:hover {
    color: #ff4444;
    /* Couleur de l'icône lors du survol */
}

.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.pagination-container span {
    margin: 0 15px;
    font-weight: bold;
    color: #fff;
    /* Ou la couleur de texte souhaitée */
}

.pagination-container button {
    background-color: #660000;
    /* Couleur de fond pour les boutons */
    color: #fff;
    /* Couleur du texte pour les boutons */
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination-container button:hover {
    background-color: #ffcccb;
    /* Couleur de fond au survol */
}

.pagination-container button:disabled {
    background-color: #333;
    /* Couleur de fond pour les boutons désactivés */
    cursor: not-allowed;
}

/* Assurez-vous que le style est cohérent avec les couleurs et le thème de votre site */