:root {
  --bg-gradient: linear-gradient(135deg, #1f2937 0%, #2d3748 60%, #1f2937 100%);
  --gold-color: #d4af37;
  --text-color: #fff;
  --hover-color: #b39565;
  --shadow-color: rgba(0, 0, 0, 0.3);
}

/* Footer principal */
.footer {
  background: var(--bg-gradient);
  color: var(--text-color);
  padding: 40px 20px;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.footer-brand h5 {
  font-family: 'Cinzel', serif;
  color: var(--gold-color);
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.footer-rights {
  font-size: 1rem;
  opacity: 0.8;
  margin-bottom: 15px;
}

/* Liens sociaux */
.footer-social {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.footer-social a {
  color: var(--text-color);
  font-size: 1.5rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.footer-social a:hover {
  color: var(--gold-color);
  transform: scale(1.2);
}

/* Crédit et liens */
.footer-credits {
  text-align: center;
  font-size: 1rem;
}

.footer-credits a {
  color: var(--gold-color);
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.footer-credits a:hover {
  color: var(--hover-color);
}

.footer-links {
  margin-top: 15px;
  font-size: 0.9rem;
}

.footer-links a {
  color: var(--text-color);
  margin: 0 10px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links span {
  color: var(--text-color);
  opacity: 0.7;
}

.footer-links a:hover {
  color: var(--gold-color);
}

/* Bas de page */
.footer-bottom {
  border-top: 1px solid #d4af37 !important; /* Couleur dorée pour correspondre aux accents du design */
  padding-top: 20px;
  margin-top: 20px;
}

.footer-bottom p {
  font-size: 0.9rem;
  opacity: 0.8;
}

.footer-social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

.footer-social-icons a {
  font-size: 1.5rem;
  color: var(--text-color);
  transition: color 0.3s ease, transform 0.3s ease;
}

.footer-social-icons a:hover {
  color: var(--gold-color);
  transform: scale(1.1);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer-top {
    flex-direction: column;
    text-align: center;
  }

  .footer-brand,
  .footer-credits {
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 20px 10px;
  }

  .footer-social a,
  .footer-social-icons a {
    font-size: 1.3rem;
  }

  .footer-links {
    font-size: 0.8rem;
  }
}
