:root {
  /* Couleurs principales */
  --bg-gradient: radial-gradient(circle at center, #1f2937 10%, #111827 90%) !important;
  --gold-color: #d4af37 !important;
  --text-color: #fff !important;
  --dark-bg: #2a2d34 !important;
  --shadow-color: rgba(0, 0, 0, 0.3) !important;
  --hover-shadow: rgba(0, 0, 0, 0.5) !important;
}

/* Conteneur Principal */
.artists-container {
  background: var(--bg-gradient) !important;
  color: var(--text-color) !important;
  padding: 120px 30px 40px !important;
  border-radius: 10px !important;
  box-shadow: 0 6px 15px var(--shadow-color) !important;
  font-family: 'Lora', serif !important;
  margin: 0 auto !important;
  position: relative !important;
  overflow: hidden !important;
}

.artists-container h1 {
  color: var(--gold-color) !important;
  font-family: 'Playfair Display', serif !important;
  text-align: center !important;
  font-size: 2.5rem !important;
  margin-bottom: 20px !important;
  position: relative !important;
  /* Légère ombre portée pour donner du relief au texte */
  text-shadow: 0 0 8px rgba(212, 175, 55, 0.3);
}

.artists-container h1::after {
  content: '' !important;
  display: block !important;
  width: 80px !important;
  height: 4px !important;
  background: var(--gold-color) !important;
  margin: 10px auto 0 auto !important;
  border-radius: 2px !important;
}

.artists-container p {
  text-align: center !important;
  color: var(--text-color) !important;
  font-family: 'Lora', serif !important;
  font-size: 1.2rem !important;
  max-width: 800px !important;
  margin: 0 auto 40px auto !important;
  line-height: 1.8 !important;
}

/* Formulaire de recherche */
form.p-5 {
  padding: 3rem !important;
  max-width: 600px !important;
  margin: 0 auto 2rem !important;
}

/* Carte des Artistes */
.artist-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
  margin-bottom: 1.5rem !important;
  background-color: var(--dark-bg) !important;
  border-radius: 12px !important;
  border: none !important;
  box-shadow: 0 4px 10px var(--shadow-color) !important;
  overflow: hidden !important;
  padding: 20px !important;
}

.artist-card:hover {
  transform: translateY(-8px) !important;
  box-shadow: 0 8px 16px var(--hover-shadow) !important;
}

/* Image de l'Artiste */
.artist-image {
  width: 250px !important;
  height: 250px !important;
  border-radius: 50% !important;
  object-fit: cover !important;
  margin: 0 auto 1rem auto !important;
  background-color: var(--dark-bg) !important;
  border: 6px solid var(--gold-color) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease, box-shadow 0.2s ease !important;
}

.artist-image:hover {
  transform: scale(1.05) !important;
  box-shadow: 0 0 15px rgba(212, 175, 55, 0.4), 0 8px 20px var(--hover-shadow) !important;
}

/* Titres et textes sur la carte */
.artist-card .card-title {
  color: var(--gold-color) !important;
  font-family: 'Playfair Display', serif !important;
  font-size: 1.5rem !important;
  margin-bottom: 0.5rem !important;
  text-align: center !important;
}

.artist-card .card-text {
  color: var(--text-color) !important;
  font-family: 'Lora', serif !important;
  font-size: 1rem !important;
  text-align: center !important;
  margin-bottom: 10px !important;
}

/* Liens sociaux */
.social-links {
  display: flex !important;
  justify-content: center !important;
  gap: 15px !important;
  margin-top: 15px !important;
}

.social-icon {
  font-size: 1.5rem !important;
  color: var(--gold-color) !important;
  transition: color 0.3s ease, transform 0.2s ease !important;
}

.social-icon:hover {
  color: #ffffff !important;
  transform: scale(1.2) !important;
}

/* Boutons d'action */
.artist-actions .action-button {
  display: block !important;
  width: 100% !important;
  margin-top: 5px !important;
  font-family: 'Playfair Display', serif !important;
  font-size: 0.9rem !important;
}

.add-artist-button {
  display: block !important;
  margin: 1rem auto !important;
  width: fit-content !important;
  background-color: var(--gold-color) !important;
  color: var(--dark-bg) !important;
  border: none !important;
  font-family: 'Playfair Display', serif !important;
  font-size: 1rem !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease !important;
}

.add-artist-button:hover {
  background-color: #b39565 !important;
  color: #fff !important;
  transform: scale(1.05) !important;
}

/* Pagination */
.pagination {
  display: flex !important;
  justify-content: center !important;
  margin-top: 30px !important;
  gap: 10px !important;
}

.pagination button {
  background: var(--dark-bg) !important;
  color: var(--gold-color) !important;
  border: 1px solid var(--gold-color) !important;
  padding: 8px 16px !important;
  border-radius: 5px !important;
  font-family: 'Playfair Display', serif !important;
  font-size: 1rem !important;
  transition: background-color 0.3s ease, transform 0.2s ease !important;
}

.pagination button:hover {
  background: var(--gold-color) !important;
  color: var(--dark-bg) !important;
  transform: scale(1.1) !important;
}

/* Largeurs réduites (tablettes) */
@media (max-width: 768px) {
  .artist-image {
    width: 200px !important;
    height: 200px !important;
  }

  .artist-card .card-title {
    font-size: 1.3rem !important;
  }

  .artist-card .card-text {
    font-size: 0.95rem !important;
  }

  form.p-5 {
    padding: 1.5rem !important;
  }
}

/* Très petits écrans (mobiles) */
@media (max-width: 480px) {
  .artist-image {
    width: 150px !important;
    height: 150px !important;
  }

  .artist-card .card-title {
    font-size: 1.1rem !important;
  }

  .artist-card .card-text {
    font-size: 0.9rem !important;
  }

  .add-artist-button {
    font-size: 0.9rem !important;
    padding: 8px 15px !important;
  }

  form.p-5 {
    padding: 1rem !important;
  }
}
