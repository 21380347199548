:root {
  --bg-gradient: linear-gradient(135deg, #1f2937 0%, #2d3748 60%, #1f2937 100%);
  --text-color: #fff;
  --primary-color: #d4af37;
  --hover-color: #920b0b;
  --card-bg: #2d3748;
  --light-text-color: #ddd;
  --shadow-color: rgba(0, 0, 0, 0.3);
  --author-bg: rgba(0, 0, 0, 0.7);
}

/* Conteneur principal des articles */
.articles-display-container {
  background: var(--bg-gradient) !important;
  padding: 2rem 1rem !important;
  color: var(--text-color) !important;
  border-bottom: 2px solid #d4af37 !important; /* Couleur dorée pour correspondre aux accents du design */
}

/* Section Latest News */
.articles-display-header {
  padding: 3rem 1rem !important;
  text-align: center !important;
}

.articles-display-header h1 {
  font-family: 'Cinzel', serif !important;
  color: var(--primary-color) !important;
  font-size: 2.8rem !important;
  margin-bottom: 1.5rem !important;
  position: relative !important;
}

.articles-display-header h1::after {
  content: '' !important;
  display: block !important;
  width: 80px !important;
  height: 4px !important;
  background: var(--primary-color) !important;
  margin: 0.5rem auto 0 !important;
  border-radius: 2px !important;
}

.articles-display-header p {
  font-family: 'Libre Baskerville', serif !important;
  color: var(--light-text-color) !important;
  font-size: 1.2rem !important;
  max-width: 70% !important;
  line-height: 1.8 !important;
  margin: auto !important;
}

/* Conteneur des articles */
.articles-display-grid {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
  gap: 2rem !important;
  padding: 3rem 1rem !important;
}

/* Carte d'article */
.articles-display-card {
  background: var(--card-bg) !important;
  border-radius: 12px !important;
  overflow: hidden !important;
  box-shadow: 0 4px 10px var(--shadow-color) !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
  position: relative !important;
  padding: 1rem !important;
}

.articles-display-card:hover {
  transform: translateY(-8px) !important;
  box-shadow: 0 8px 20px var(--shadow-color) !important;
}

.articles-display-meta {
  display: flex !important;
  justify-content: space-between !important;
  font-size: 0.85rem !important;
  position: absolute !important;
  top: 10px !important;
  width: calc(100% - 20px) !important; /* Réduit pour éviter que ça touche les bords */
  padding: 0 10px !important;
  color: var(--text-color) !important;
}

.articles-display-author,
.articles-display-date {
  background-color: var(--author-bg) !important;
  padding: 0.3rem 0.8rem !important;
  border-radius: 5px !important;
}

.articles-display-image {
  width: 100% !important;
  height: 180px !important;
  object-fit: cover !important;
  border-bottom: 2px solid var(--hover-color) !important;
  border-radius: 10px 10px 0 0 !important;
}

.articles-display-card .card-body {
  display: flex !important;
  flex-direction: column !important;
  gap: 1rem !important;
  padding: 1rem !important;
}

.articles-display-card .card-title {
  font-size: 1.3rem !important;
  margin-bottom: 0.5rem !important;
  color: var(--primary-color) !important;
  font-weight: bold !important;
  font-family: 'Cinzel', serif !important;
  text-align: center !important;
}

.articles-display-card .card-text {
  color: var(--light-text-color) !important;
  font-size: 1rem !important;
  margin-bottom: 1rem !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-align: justify !important;
}

/* Boutons */
.articles-display-actions {
  display: flex !important;
  gap: 0.5rem !important;
  justify-content: center !important;
}

.articles-display-actions .btn {
  font-size: 0.9rem !important;
  padding: 0.5rem 1rem !important;
  transition: background-color 0.3s ease, transform 0.2s ease !important;
  border-radius: 5px !important;
}

.articles-display-actions .btn-primary {
  background-color: var(--primary-color) !important;
  color: #fff !important;
  border: none !important;
}

.articles-display-actions .btn-primary:hover {
  background-color: var(--hover-color) !important;
  transform: scale(1.05) !important;
}

.articles-display-actions .btn-danger {
  background-color: #b71c1c !important;
  color: #fff !important;
}

.articles-display-actions .btn-danger:hover {
  background-color: #e53935 !important;
}

.articles-display-container .articles-display-pagination {
  display: flex !important;
  justify-content: center !important;
  padding: 2rem 0 !important;
  gap: 0.5rem !important;
}

.articles-display-pagination .page-item {
  margin: 0 !important;
}
.articles-display-pagination .page-item.disabled .page-link {
  color: var(--light-text-color) !important;           /* Couleur du texte pour un état désactivé */
  background-color: var(--card-bg) !important;        /* Fond identique aux autres éléments */
  border-color: rgba(212, 175, 55, 0.5) !important;   /* Bordure dorée subtile */
  pointer-events: none !important;                   /* Désactive les clics */
  cursor: not-allowed !important;                    /* Change le curseur pour indiquer un état non cliquable */
  opacity: 0.6 !important;                           /* Réduit l'opacité pour signifier qu'il est désactivé */
}


.articles-display-pagination .page-link:hover {
  color: #fff !important;
  background-color: var(--hover-color) !important;
  transform: translateY(-2px) !important;
}

.articles-display-pagination .page-item.active .page-link {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  transform: scale(1.05) !important;
}

.articles-display-pagination .page-link:first-child,
.articles-display-pagination .page-link:last-child,
.articles-display-pagination .page-link:nth-child(2),
.articles-display-pagination .page-link:nth-last-child(2) {
  font-size: 0.9rem !important;
  padding: 0.4rem 0.6rem !important;
}




/* Responsive adjustments */
@media (max-width: 992px) {
  .articles-display-grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .articles-display-image {
    height: 200px !important;
  }
}

@media (max-width: 768px) {
  .articles-display-grid {
    grid-template-columns: 1fr !important;
  }

  .articles-display-header h1 {
    font-size: 2.4rem !important;
  }

  .articles-display-header p {
    font-size: 1.1rem !important;
    max-width: 90% !important;
  }
}

@media (max-width: 480px) {
  .articles-display-image {
    height: 180px !important;
  }

  .articles-display-header h1 {
    font-size: 2rem !important;
  }

  .articles-display-header p {
    font-size: 1rem !important;
    padding: 0 5% !important;
  }

  .articles-display-grid {
    padding-top: 2rem !important;
  }
}
