.article-edit-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .article-edit-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .article-edit-container form label {
    display: block;
    margin-bottom: 5px;
  }
  
  .article-edit-container form input[type="text"],
  .article-edit-container form input[type="date"] {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .article-edit-container form textarea {
    width: 100%;
    min-height: 200px;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .article-edit-container form button {
    padding: 8px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .article-edit-container form button:hover {
    background-color: #0056b3;
  }
  
  .article-edit-container form button[type="button"] {
    background-color: #6c757d;
  }
  
  .article-edit-container form button[type="button"]:hover {
    background-color: #5a6268;
  }
  
  .article-edit-container form button:last-child {
    margin-right: 0;
  }
  