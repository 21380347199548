:root {
    --bg-gradient: linear-gradient(135deg, #1f2937 0%, #2d3748 60%, #1f2937 100%);
    --gold-color: #d4af37;
    --text-color: #ffffff;
    --link-hover-color: #b39565;
    --shadow-color: rgba(0, 0, 0, 0.3);
}

/* Conteneur principal */
.terms-conditions-container {
    background: var(--bg-gradient);
    color: var(--text-color);
    padding: 40px 20px;
    box-shadow: 0px 0px 20px var(--shadow-color);
    padding-top: 120px;
    animation: fadeIn 0.5s ease-out;
}

/* Titre principal */
.terms-conditions-title {
    color: var(--gold-color);
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.terms-icon {
    margin-right: 10px;
    font-size: 2rem;
}

.terms-conditions-date {
    text-align: center;
    font-size: 1rem;
    opacity: 0.8;
    margin-bottom: 40px;
}

/* Sections */
.terms-section {
    margin-bottom: 40px;
}

.terms-section-title {
    color: var(--gold-color);
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--gold-color);
    padding-bottom: 10px;
}

.terms-section p {
    font-size: 1rem;
    line-height: 1.8;
    margin-bottom: 20px;
}

.terms-section a {
    color: var(--gold-color);
    text-decoration: none;
    transition: color 0.3s ease;
}

.terms-section a:hover {
    color: var(--link-hover-color);
}

/* Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Styles */
@media (max-width: 768px) {
    .terms-conditions-container {
        padding: 30px 15px;
    }

    .terms-conditions-title {
        font-size: 2rem;
    }

    .terms-section-title {
        font-size: 1.3rem;
    }

    .terms-section p {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .terms-conditions-container {
        padding: 20px 10px;
    }

    .terms-conditions-title {
        font-size: 1.8rem;
    }

    .terms-section-title {
        font-size: 1.2rem;
    }

    .terms-section p {
        font-size: 0.85rem;
    }
}
