/* src/components/Contact.css */
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;600&family=Playfair+Display:wght@400;700&display=swap');

/* Couleurs et variables */
:root {
    --bg-gradient: radial-gradient(circle at center, rgba(31, 41, 55, 0.5), rgba(0, 0, 0, 0.9)) !important;
    --text-color: #fff !important;
    --gold-color: #d4af37 !important;
    --shadow-color: rgba(0, 0, 0, 0.3) !important;
    --overlay-color: rgba(31, 41, 55, 0.7) !important; /* Couleur du voile */
    --focus-glow: rgba(212, 175, 55, 0.6) !important;  /* Pour l’effet glow focus */
}

/* Conteneur principal */
.contact-container {
    position: relative !important;
    /* Supprimez ou commentez la ligne suivante pour ne pas écraser l'image de fond : */
    /* background: var(--bg-gradient) !important; */
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    padding: 5rem 0 !important;
    color: var(--text-color) !important;
    min-height: 100vh !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5) !important;
    overflow: hidden !important;
}


/* Superposition pour le filtre */
.contact-overlay {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background: var(--overlay-color) !important;
    z-index: 1 !important;
}


/* Bloc du formulaire */
.contact-form-container {
    position: relative !important;
    background: rgba(33, 37, 41, 0.85) !important;
    padding: 2rem !important;
    border-radius: 0.75rem !important;
    box-shadow: 0 0.5rem 1rem var(--shadow-color) !important;
    width: 100% !important;
    max-width: 40rem !important;
    z-index: 2 !important;
    /* Transition légère sur le hover */
    transition: transform 0.3s ease, box-shadow 0.3s ease !important;
    border: 1px solid rgba(212, 175, 55, 0.2) !important; /* Liseré doré discret */
}

.contact-form-container:hover {
    transform: scale(1.02) !important;
    box-shadow: 0 0.5rem 1.5rem rgba(0,0,0,0.5), 0 0 12px rgba(212, 175, 55, 0.2) !important;
}

/* Titre et description */
.contact-title {
    font-family: 'Playfair Display', serif !important;
    font-size: 2.5rem !important;
    color: var(--gold-color) !important;
    text-align: center !important;
    margin-bottom: 1rem !important;
    text-shadow: 0 0 6px rgba(212,175,55,0.3);
}

.contact-description {
    font-family: 'Lora', serif !important;
    font-size: 1.2rem !important;
    text-align: center !important;
    margin-bottom: 2rem !important;
    color: #f0f0f0 !important;
}

/* Formulaire */
.contact-form {
    width: 100% !important;
}

.contact-form-group {
    margin-bottom: 1.5rem !important;
}

.contact-form-label {
    font-weight: bold !important;
    font-size: 1.1rem !important;
    color: var(--gold-color) !important;
    margin-bottom: 0.5rem !important;
    display: inline-block !important;
}

.contact-form-control {
    background: none !important;
    border: 2px solid rgba(212,175,55,0.5) !important;
    border-radius: 0.25rem !important;
    color: #f8e8c2 !important;
    margin-bottom: 1rem !important;
    box-shadow: none !important;
    transition: border-color 0.3s ease, box-shadow 0.3s ease !important;
    font-family: 'Lora', serif !important;
}

.contact-form-control::placeholder {
    color: #ddd !important;
}

.contact-form-control:focus {
    border-color: var(--gold-color) !important;
    background: rgba(255, 255, 255, 0.07) !important;
    box-shadow: 0 0 8px var(--focus-glow) !important;
    color: #fff !important;
}

/* Bouton d’envoi */
.contact-submit-button {
    background-color: var(--gold-color) !important;
    color: #000 !important;
    padding: 0.75rem 1.5rem !important;
    font-size: 1rem !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    border: none !important;
    border-radius: 5px !important;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease !important;
    cursor: pointer !important;
    display: block !important;
    margin: 0 auto !important;
    font-family: 'Playfair Display', serif !important;
    letter-spacing: 1px !important;
}

.contact-submit-button:hover {
    background-color: #660000 !important;
    color: #fff !important;
    transform: scale(1.06) !important;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4) !important;
}

/* Alert */
.contact-alert {
    border-radius: 0.25rem !important;
    font-family: 'Lora', serif !important;
    font-size: 1rem !important;
    text-align: center !important;
}

/* Responsive styles */
@media (max-width: 768px) {
    .contact-container {
        padding: 2rem 0 !important;
    }
    .contact-form-container {
        padding: 1.5rem !important;
        max-width: 90% !important;
    }
    .contact-title {
        font-size: 2rem !important;
        margin-bottom: 1rem !important;
    }
    .contact-description {
        font-size: 1rem !important;
        margin-bottom: 1.5rem !important;
    }
    .contact-form-control {
        font-size: 0.9rem !important;
    }
    .contact-submit-button {
        padding: 0.6rem 1.2rem !important;
        font-size: 0.9rem !important;
    }
}

@media (max-width: 480px) {
    .contact-title {
        font-size: 1.8rem !important;
    }
    .contact-form-container {
        padding: 1rem !important;
    }
    .contact-description {
        font-size: 0.9rem !important;
    }
    .contact-form-control {
        font-size: 0.85rem !important;
    }
    .contact-submit-button {
        font-size: 0.85rem !important;
        padding: 0.5rem 1rem !important;
    }
}
