@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Roboto:wght@400;500&display=swap');

/* Barre de navigation transparente par défaut */
.navbar-opera {
    background-color: transparent !important;
    transition: background-color 0.5s ease, box-shadow 0.5s ease !important;
    font-family: 'Playfair Display', serif !important;
    box-shadow: none !important;
    border-bottom: none !important;
}

/* Fond et bordure réapparaissent au défilement avec une légère transparence */
.navbar-opera.scrolled {
    background-color: rgba(31, 41, 55, 0.9) !important; /* Fond sombre pour un style cohérent */
    border-bottom: 2px solid #d4af37 !important; /* Couleur dorée pour correspondre aux accents du design */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
}

/* Centrage et styles des liens de navigation */
.nav-center {
    margin: 0 auto !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 20px !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    text-transform: uppercase !important; /* Uniformise le style des liens */
}

/* Espacement et styles des liens */
.navbar-opera .nav-link {
    padding: 0.5rem 1rem !important;
    color: #ffffff !important;
    letter-spacing: 1px !important;
    transition: color 0.3s ease, border-bottom 0.3s ease !important;
    text-decoration: none !important;
}

/* Lien actif ou survolé */
.navbar-opera .nav-link:hover,
.navbar-opera .nav-link.active {
    color: #d4af37 !important; /* Couleur dorée pour le focus */
    border-bottom: 2px solid #d4af37 !important;
}

/* Dropdown amélioré pour plus de lisibilité */
.dropdown-menu {
    border-radius: 5px !important;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2) !important;
    background-color: #1f2937 !important;
    border: 1px solid #660000 !important;
}

.dropdown-item {
    color: #d4af37 !important; /* Couleur dorée pour les éléments */
    transition: background-color 0.3s ease, color 0.3s ease !important;
}

.dropdown-item:hover {
    background-color: rgba(102, 0, 0, 0.8) !important;
    color: #ffffff !important;
}

/* Gestion des logos et des transitions */
.navbar-brand img {
    height: 40px !important;
    transition: height 0.3s ease, opacity 0.3s ease !important;
}

.navbar-opera.scrolled .navbar-brand img {
    height: 30px !important;
    opacity: 0.9 !important;
}

/* Ajout d'une transition globale pour un effet doux */
.navbar-opera {
    transition: background-color 0.5s ease, border-bottom 0.5s ease, box-shadow 0.5s ease !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .nav-center {
        flex-direction: column !important;
        gap: 10px !important;
    }

    .navbar-opera .nav-link {
        font-size: 14px !important;
        padding: 0.5rem !important;
    }
}
