/* Structure principale */
.performance-list-container {
    margin: 0;
    background: linear-gradient(135deg, #1f2937 0%, #2d3748 60%, #1f2937 100%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
    font-family: 'Playfair Display', serif !important;
    padding-top: 80px !important;
}

/* Introduction */
.performance-list-introduction {
    text-align: center !important;
    padding: 40px 20px !important;
    background: linear-gradient(135deg, #1f2937 0%, #2d3748 60%, #1f2937 100%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
    border-radius: 8px !important;
    margin-bottom: 30px !important;
}

.performance-list-title {
    color: #d4af37 !important;
    font-size: 3rem !important;
    font-weight: bold !important;
    margin-bottom: 20px !important;
}

.performance-list-description {
    color: #ffffff !important;
    font-size: 1.3rem !important;
    max-width: 100% !important;
    margin: auto !important;
    line-height: 1.6 !important;
}

@media (max-width: 768px) {
    .performance-list-description {
        font-size: 1.1rem !important;
        max-width: 90% !important;
    }
}

/* Onglets */
.performance-list-tabs {
    margin-bottom: 20px !important;
}

.nav-tabs {
    justify-content: center !important;
    border-bottom: 1px solid #660000 !important;
    flex-wrap: wrap;
}

.nav-tabs .nav-link {
    color: #ffffff !important;
    background-color: #3c3c3c !important;
    border: 1px solid transparent !important;
    border-radius: 5px 5px 0 0 !important;
    transition: all 0.3s ease !important;
    padding: 10px 20px !important;
    margin: 5px;
    font-size: 1rem;
}

.nav-tabs .nav-link:hover {
    background-color: #d4af37 !important;
    color: #660000 !important;
}

.nav-tabs .nav-link.active {
    background-color: #d4af37 !important;
    color: #660000 !important;
    border: 1px solid #d4af37 !important;
    border-bottom-color: transparent !important;
}

/* Liste des performances */
.performance-list-upcoming,
.performance-list-past {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(550px, 1fr)) !important;
    gap: 20px !important;
    padding: 10px 20px !important;
}

@media (max-width: 768px) {
    .performance-list-upcoming,
    .performance-list-past {
        grid-template-columns: 1fr !important;
        padding: 10px !important;
    }
}

/* Item de la performance */
.performance-list-item {
    display: flex !important;
    flex-direction: row !important;
    background: linear-gradient(135deg, #1f2937 0%, #2d3748 60%, #1f2937 100%);
    border-radius: 10px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    overflow: hidden !important;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
}

.performance-list-item:hover {
    transform: translateY(-5px) !important;
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2) !important;
}

/* Image */
.performance-list-item-image {
    flex: 1 !important;
    max-width: 40% !important;
    overflow: hidden !important;
    background-color: #121212 !important;
    position: relative;
}

.performance-list-item-image img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    transition: transform 0.3s ease !important;
}

.performance-list-item-image:hover img {
    transform: scale(1.1) !important;
}

/* Détails */
.performance-list-item-details {
    padding: 20px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    gap: 15px !important;
}

.performance-list-item-title {
    font-size: 1.8rem !important;
    font-weight: bold !important;
    color: #d4af37 !important;
    margin: 0 0 10px 0;
}

.performance-list-item-dates,
.performance-list-item-location,
.performance-list-item-director,
.performance-list-item-conductor {
    font-size: 1rem !important;
    color: #ffffff !important;
    margin-bottom: 5px !important;
}

.performance-list-item-dates strong,
.performance-list-item-location strong,
.performance-list-item-director strong,
.performance-list-item-conductor strong {
    color: #d4af37 !important;
}

/* Bouton Supprimer */
.performance-list-item-delete-button {
    align-self: flex-start !important;
    background-color: #660000 !important;
    color: #ffffff !important;
    border: none !important;
    padding: 8px 15px !important;
    border-radius: 5px !important;
    transition: background-color 0.3s ease !important;
    margin-top: 10px !important;
}

.performance-list-item-delete-button:hover {
    background-color: #d4af37 !important;
    color: #660000 !important;
}

/* Modal */
.performance-list-modal .modal-header {
    background-image: linear-gradient(to right, #330000, #0d0d0d) !important;
    color: #ffffff !important;
    border-bottom: none !important;
    padding: 20px !important;
    text-align: center !important;
}

.performance-list-modal .modal-body {
    background-color: #1a1a1a !important;
    color: #ffffff !important;
    padding: 20px !important;
}

.performance-list-modal .modal-footer {
    background-color: #1a1a1a !important;
    padding: 20px !important;
}

.performance-list-modal .form-control {
    background-color: #2b2b2b !important;
    color: #ffffff !important;
    border: 1px solid #660000 !important;
}

.performance-list-modal .form-control:focus {
    background-color: #3c3c3c !important;
    border-color: #d4af37 !important;
    color: #ffffff !important;
    box-shadow: none !important;
}

.performance-list-modal .modal-footer .btn-secondary {
    background-color: #2b2b2b !important;
    color: #d4af37 !important;
}

.performance-list-modal .modal-footer .btn-primary {
    background-color: #660000 !important;
    color: #ffffff !important;
}

.performance-list-modal .modal-footer .btn-secondary:hover {
    background-color: #d4af37 !important;
    color: #660000 !important;
}

.performance-list-modal .modal-footer .btn-primary:hover {
    background-color: #d4af37 !important;
    color: #660000 !important;
}

/* Responsive */
@media (max-width: 768px) {
    .performance-list-item {
        flex-direction: column !important;
    }

    .performance-list-item-image {
        max-width: 100% !important;
        height: 200px; /* Hauteur fixe pour les images sur mobile */
    }

    .performance-list-item-image img {
        height: 100% !important;
        width: 100% !important;
    }

    .performance-list-item-details {
        padding: 15px !important;
    }

    .performance-list-title {
        font-size: 2.5rem !important;
    }

    .nav-tabs .nav-link {
        font-size: 0.9rem !important;
        padding: 8px 15px !important;
    }
}
