@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&family=Playfair+Display:wght@400;700&display=swap');

/* Jumbotron - Image de fond */
.jumbotron.home {
  position: relative !important;
  overflow: hidden !important;
  height: 100vh !important; /* Occupe 100% de la hauteur de l'écran */
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  color: #ffffff !important;
  font-family: 'Playfair Display', serif !important;
  background: linear-gradient(135deg, #1f2937 0%, #2d3748 60%, #1f2937 100%);
  transition: opacity 0.5s ease !important;
  margin: 0 !important;
  padding: 0 !important;
}

.jumbotron.home.loaded {
    opacity: 1 !important;
}

/* Texte du Jumbotron */
.jumbotron.home h1 {
    font-size: 3.5rem !important;
    font-weight: 700 !important;
    color: #d4af37 !important; /* Or moderne */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7) !important;
    margin-bottom: 20px !important;
}

.jumbotron.home p {
    font-size: 1.2rem !important;
    line-height: 1.8 !important;
    color: #ffffff !important;
    margin-top: 20px !important;
}

/* Bouton dans le Jumbotron */
.jumbotron.home .btn-primary {
    background-color: #660000 !important;
    border: none !important;
    padding: 10px 25px !important;
    font-size: 1.2rem !important;
    font-weight: bold !important;
    transition: background-color 0.3s ease !important;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2) !important;
}

.jumbotron.home .btn-primary:hover {
    background-color: #803131 !important;
}

/* Section Bio */
.bio-section {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 60px 10% !important; /* Augmentation du padding pour plus d'espace */
  background: linear-gradient(135deg, rgba(31,41,55,0.95) 0%, rgba(45,55,72,0.95) 60%, rgba(31,41,55,0.95) 100%);
  border-top: 2px solid #d4af37 !important;
  border-bottom: 2px solid #d4af37 !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* Ombre pour créer de la profondeur */
  overflow: hidden !important;
  flex-wrap: wrap;
}

/* Conteneur de l'indicateur de défilement */
.scroll-indicator {
  position: absolute;
  bottom: 20px; 
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 50px;
  height: 50px;
  cursor: pointer;
  border: 2px solid #d4af37; /* Contour doré */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  /* Ajout d’un halo doré autour et d’un ombrage interne pour la profondeur */
  box-shadow: 
    0 0 8px rgba(212, 175, 55, 0.4),
    inset 0 0 6px rgba(212, 175, 55, 0.2);
  /* Animation “pulsation” légère pour le cercle */
  animation: pulse-circle 2s infinite;
  transition: box-shadow 0.3s ease;
}

/* Effet de survol : halo plus intense */
.scroll-indicator:hover {
  box-shadow: 
    0 0 12px rgba(212, 175, 55, 0.6),
    inset 0 0 8px rgba(212, 175, 55, 0.3);
}

.scroll-indicator .arrow {
  position: relative;
  width: 12px;
  height: 12px;
  /* Flèche dorée légèrement plus épaisse */
  border-right: 3px solid #d4af37; 
  border-bottom: 3px solid #d4af37;
  transform: rotate(45deg);
  /* Animation de rebond */
  animation: bounce-arrow 1.5s ease-in-out infinite;
}

/* Animation cercle : pulsation légère en jouant sur l’échelle et l’opacité */
@keyframes pulse-circle {
  0%, 100% {
    transform: translateX(-50%) scale(1);
    opacity: 1;
  }
  50% {
    transform: translateX(-50%) scale(0.97);
    opacity: 0.85;
  }
}

/* Animation flèche : rebond vertical */
@keyframes bounce-arrow {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0) rotate(45deg);
  }
  40% {
    transform: translateY(6px) rotate(45deg);
  }
  60% {
    transform: translateY(3px) rotate(45deg);
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
}



.bio-image {
  max-width: 25%;  /* Légère augmentation pour une meilleure visibilité */
  height: auto !important;
  border-radius: 12px !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2) !important;
  object-fit: cover !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
  margin: 0 auto 20px auto;
  border: 3px solid #d4af37; /* Ajout d'une bordure dorée pour la cohérence */
}

.bio-image:hover {
  transform: scale(1.05) !important;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4) !important;
}


/* Texte de la Biographie */
.bio-text {
  max-width: 50% !important;
  margin-left: 5% !important;
  color: #ffffff !important;
  font-family: 'Roboto', sans-serif !important;
  padding: 20px !important;
  background: linear-gradient(135deg, #1f2937 0%, #2d3748 60%, #1f2937 100%);
  border-radius: 12px !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3) !important;
  overflow: hidden !important;
  animation: fadeIn 1s ease-out !important;
}

.bio-text p {
  font-size: 1.1rem !important;
  line-height: 1.8 !important;
  text-align: justify !important;
  margin-bottom: 15px !important;
  color: #ffffff !important;
}

/* Bouton Lire Ma Biographie */
.bio-button {
  display: inline-block !important;
  background-color: #d4af37 !important; /* Couleur dorée comme couleur de base */
  color: #660000 !important; /* Texte en rouge pour correspondre au thème */
  padding: 10px 20px !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  border: none !important;
  border-radius: 5px !important;
  text-align: center !important;
  text-decoration: none !important;
  transition: background-color 0.3s ease, transform 0.3s ease !important;
  cursor: pointer !important;
  margin-top: 15px !important;
}

.bio-button:hover {
  background-color: #660000 !important; /* Rouge intense au survol */
  color: #ffffff !important; /* Texte en blanc pour contraste */
  transform: scale(1.05) !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4) !important;
}


/* Animation au défilement */
.bio-section {
  opacity: 0 !important;
  transform: translateX(-50px) !important;
  transition: opacity 0.8s ease, transform 0.8s ease !important;
}

.bio-section.slideIn {
  opacity: 1 !important;
  transform: translateX(0) !important;
}

/* Animation Fade-In */
@keyframes fadeIn {
  from {
      opacity: 0 !important;
      transform: translateY(20px) !important;
  }
  to {
      opacity: 1 !important;
      transform: translateY(0) !important;
  }
}

/* Dropdown Modernisé */
.dropdown-menu {
    background-color: #1a1a1a !important;
    border-radius: 8px !important;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3) !important;
}

.dropdown-item:hover {
    background-color: rgba(102, 0, 0, 0.8) !important;
    color: #ffffff !important;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .bio-section {
      flex-direction: column !important;
      text-align: center !important;
      padding: 30px 5% !important;
  }

  .bio-image {
      max-width: 80% !important;
  }

  .bio-text {
      max-width: 90% !important;
      margin: 20px auto 0 auto !important;
  }

  .bio-text h2 {
      font-size: 2rem !important;
  }

  .bio-text p {
      font-size: 1rem !important;
  }
}

@media (max-width: 768px) {
  .bio-section {
      padding: 20px 5% !important;
  }

  .bio-image {
      max-width: 90% !important;
  }

  .bio-text {
      max-width: 100% !important;
      padding: 15px !important;
      margin: 0 auto !important;
  }

  .bio-text h2 {
      font-size: 1.8rem !important;
  }

  .bio-button {
      padding: 8px 15px !important;
      font-size: 0.9rem !important;
  }

  .jumbotron.home h1 {
      font-size: 2.5rem !important;
  }
}

/* Ajustements supplémentaires pour les très petits écrans */
@media (max-width: 480px) {
  .jumbotron.home h1 {
      font-size: 2rem !important;
  }

  .jumbotron.home p {
      font-size: 1rem !important;
  }

  .bio-text h2 {
      font-size: 1.5rem !important;
  }

  .bio-text p {
      font-size: 0.9rem !important;
  }

  .bio-button {
      padding: 6px 12px !important;
      font-size: 0.8rem !important;
  }
}
