:root {
    --bg-gradient: linear-gradient(135deg, #1f2937 0%, #2d3748 60%, #1f2937 100%) !important;
    --gold-color: #d4af37 !important;
    --text-color: #ffffff !important;
    --shadow-color: rgba(0, 0, 0, 0.3) !important;
    --link-hover-color: #b39565 !important;
}
  
/* Conteneur principal */
.privacy-policy-container {
    background: var(--bg-gradient) !important;
    color: var(--text-color) !important;
    padding: 40px 20px !important;
    box-shadow: 0px 0px 20px var(--shadow-color) !important;
    animation: fadeIn 0.5s ease-out !important;
    padding-top: 120px !important;
}
  
/* Titres principaux */
.privacy-policy-title {
    color: var(--gold-color) !important;
    font-family: 'Playfair Display', serif !important;
    font-size: 2.5rem !important;
    text-align: center !important;
    margin-bottom: 20px !important;
}
  
.privacy-policy-date {
    text-align: center !important;
    font-size: 1rem !important;
    opacity: 0.8 !important;
    margin-bottom: 40px !important;
}
  
/* Sections */
.privacy-section {
    margin-bottom: 40px !important;
}
  
.privacy-section h2 {
    color: var(--gold-color) !important;
    font-size: 1.5rem !important;
    display: flex !important;
    align-items: center !important;
    margin-bottom: 20px !important;
    border-bottom: 2px solid var(--gold-color) !important;
    padding-bottom: 10px !important;
}
  
.privacy-icon {
    margin-right: 10px !important;
    font-size: 1.5rem !important;
}
  
/* Paragraphes et listes */
.privacy-section p {
    font-size: 1rem !important;
    line-height: 1.8 !important;
    margin-bottom: 20px !important;
}
  
.privacy-section ul {
    margin-left: 20px !important;
    padding-left: 20px !important;
    list-style-type: disc !important;
}
  
.privacy-section li {
    font-size: 1rem !important;
    margin-bottom: 10px !important;
}
  
/* Liens */
.privacy-link {
    color: var(--gold-color) !important;
    text-decoration: none !important;
    transition: color 0.3s ease !important;
}
  
.privacy-link:hover {
    color: var(--link-hover-color) !important;
}
  
/* Animation */
@keyframes fadeIn {
    from {
        opacity: 0 !important;
        transform: translateY(-20px) !important;
    }
    to {
        opacity: 1 !important;
        transform: translateY(0) !important;
    }
}
  
/* Responsive Styles */
@media (max-width: 768px) {
    .privacy-policy-container {
        padding: 30px 15px !important;
    }
  
    .privacy-policy-title {
        font-size: 2rem !important;
    }
  
    .privacy-section h2 {
        font-size: 1.3rem !important;
    }
  
    .privacy-section p,
    .privacy-section li {
        font-size: 0.9rem !important;
    }
}
  
@media (max-width: 480px) {
    .privacy-policy-container {
        padding: 20px 10px !important;
        padding-top: 80px !important;
    }
  
    .privacy-policy-title {
        font-size: 1.8rem !important;
    }
  
    .privacy-section h2 {
        font-size: 1.2rem !important;
    }
  
    .privacy-section p,
    .privacy-section li {
        font-size: 0.85rem !important;
    }
}
