/* src/components/SignUpModal.css */
.signup-modal-header {
    background-image: linear-gradient(to right, #330000, #0d0d0d);
    color: #ffffff;
}

.signup-modal-body {
    background-color: #1a1a1a;
    color: #ffffff;
}

.signup-modal-button, .google-signin-button {
    margin-top: 20px;
    width: 100%;
}

.google-signin-button {
    background-color: #4285f4;
    border-color: #4285f4;
    color: #ffffff;
}

.google-signin-button:hover {
    background-color: #357ae8;
    border-color: #357ae8;
}

.signup-modal-button {
    background-color: #660000;
    border-color: #660000;
    color: #ffffff;
}

.signup-modal-button:hover {
    background-color: #ffcccb;
    border-color: #ffcccb;
    color: #660000;
}

/* Styles spécifiques pour les icônes dans les labels de formulaire */
.form-label {
    display: flex;
    align-items: center;
    color: #ffffff;
}

.form-label svg {
    margin-right: 8px;
}

/* Amélioration de l'apparence des champs de saisie */
.form-control {
    background-color: #2b2b2b;
    color: #ffffff;
    border: 1px solid #660000;
}

.form-control:focus {
    background-color: #3c3c3c;
    border-color: #ffcccb;
    color: #ffffff;
    box-shadow: none;
}

/* Alertes stylisées pour une meilleure intégration dans le thème */
.alert-danger {
    background-color: #660000;
    border-color: #660000;
    color: #ffffff;
}
/* Changement de la couleur du bouton de fermeture de la modal en blanc */
.signup-modal .modal-header .close {
    color: #ffffff;
    opacity: 1; /* Assurez-vous que le bouton est bien visible */
}

/* Ou pour Bootstrap 5, vous pouvez avoir besoin de cibler .btn-close */
.signup-modal .modal-header .btn-close {
    filter: invert(1); /* Inverse la couleur du bouton pour le rendre blanc */
    opacity: 1;
}
