/* ArticleForm.css */
.form-container {
    background-color: #f4f4f4;
    /* Couleur de fond légère */
    padding: 2rem;
    border-radius: 10px;
    /* Bords arrondis pour le formulaire */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Ombre portée légère */
    max-width: 800px;
    /* Largeur maximale du formulaire */
    margin: 2rem auto;
    /* Centrer le formulaire */
}

.form-container form {
    display: flex;
    flex-direction: column;
}

.form-input,
.react-quill {
    margin-bottom: 1rem;
    /* Espacement entre les champs */
    border-radius: 4px;
    /* Bords arrondis pour les champs */
    color: black;
}

.form-input {
    border: 1px solid #ccc;
    /* Bordure des champs de saisie */
    padding: 0.5rem;
    /* Padding interne pour les champs de saisie */
}

.form-button {
    background-color: #920b0b;
    /* Couleur du bouton */
    color: white;
    /* Couleur du texte du bouton */
    border: none;
    /* Retirer la bordure du bouton */
    padding: 0.75rem;
    /* Padding du bouton */
    cursor: pointer;
    /* Curseur de pointeur */
    transition: background-color 0.3s ease;
    /* Transition douce pour le survol du bouton */
}

.form-button:hover {
    background-color: #660000;
    /* Couleur du bouton au survol */
}

.form-input,
.form-date {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.react-quill {
    height: 250px;
    /* Hauteur plus grande pour le champ de contenu */
}

.image-url-input {
    display: flex;
    margin-bottom: 10px;
}

/* Bouton pour ajouter ou supprimer des images */
.image-url-button {
    margin-left: 10px;
    cursor: pointer;
}

/* Ajustements pour le bouton de soumission */
.form-button {
    margin-top: 20px;
    /* Espacement avant le bouton de soumission */
}

/* Animation pour les champs du formulaire */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.form-container {
    animation: fadeInUp 0.5s ease-out;
}