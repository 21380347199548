/* ArticleDetails.css */
.article-details-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
    color: #fff;
    font-family: 'Libre Baskerville', serif;
}

.article-title {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 20px;
    color: #d4af37; /* Gold color */
}

.article-metadata {
    display: flex;
    justify-content: space-between;
    align-items: center; /* To vertically align with the author name and date */
    margin-bottom: 20px;
    font-size: 1rem;
}

.article-author,
.article-date {
    font-style: italic;
}

.article-content {
    margin-bottom: 20px;
    line-height: 1.6; /* Improve readability */
}

.article-cover-image,
.article-additional-image {
    width: 100%;
    margin-top: 20px;
    border-radius: 5px;
    object-fit: cover; /* Ensure images cover the area without stretching */
}

.comments-section {
    margin-top: 40px;
}

.comments-section h2 {
    display: flex;
    align-items: center;
    font-size: 2rem;
    margin-bottom: 20px;
}

.comments-section .comment {
    background: rgba(255, 255, 255, 0.1);
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
    position: relative;
}

.comments-section .comment-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; /* Space between header and comment text */
}

.comments-section .comment p {
    margin: 0;
}

.comments-section .comment-author,
.comments-section .comment-date {
    font-style: italic;
    font-size: 0.9rem;
}

.comments-section .comment svg {
    cursor: pointer;
    color: #ff4444;
    font-size: 1.5rem;
}

.comment-form {
    display: flex;
    margin-top: 20px;
}

.comment-form input {
    flex-grow: 1; /* Input field takes up remaining space */
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #d4af37; /* Gold border for the input */
    margin-right: 10px;
    background: rgba(255, 255, 255, 0.2); /* Slight background for visibility */
    color: #fff; /* White text for visibility */
}

.comment-form button {
    padding: 10px 20px;
    background: #d4af37; /* Gold background for consistency */
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background 0.3s ease; /* Smooth transition for hover effect */
}

.comment-form button:hover {
    background: #b2975b; /* Darker gold on hover */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
    .article-details-container {
        margin: 20px;
        padding: 10px;
    }

    .article-title {
        font-size: 2rem;
    }

    .comments-section h2 {
        font-size: 1.5rem;
    }

    .comment-form button {
        padding: 10px;
    }
}
