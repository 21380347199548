/* src/components/SignInModal.css */
.signin-modal-header {
    background-image: linear-gradient(to right, #330000, #0d0d0d);
    color: #ffffff;
}

.signin-modal-body {
    background-color: #1a1a1a;
}

.signin-modal-button, .google-signin-button {
    margin-top: 20px;
    width: 100%;
}

.google-signin-button {
    background-color: #4285f4;
    border-color: #4285f4;
    color: #ffffff;
}

.google-signin-button:hover {
    background-color: #357ae8;
    border-color: #357ae8;
}

.signin-modal-button {
    background-color: #660000;
    border-color: #660000;
    color: #ffffff;
}

.signin-modal-button:hover {
    background-color: #ffcccb;
    border-color: #ffcccb;
    color: #660000;
}

.form-label {
    display: flex;
    align-items: center;
    color: #ffffff;
}

.form-label svg {
    margin-right: 8px;
}

.form-control {
    background-color: #2b2b2b;
    color: #ffffff;
    border: 1px solid #660000;
}

.form-control:focus {
    background-color: #3c3c3c;
    border-color: #ffcccb;
    color: #ffffff;
    box-shadow: none;
}

/* Styles pour les messages d'alerte */
.alert-danger {
    background-color: #660000;
    border-color: #660000;
    color: #ffffff;
}

.alert-success {
    background-color: #4BB543;
    border-color: #4BB543;
    color: #ffffff;
}

/* Amélioration visuelle pour le bouton de fermeture */
.signin-modal .modal-header .btn-close {
    filter: invert(1);
    opacity: 1;
}

/* Ajout d'un peu d'espace entre les champs du formulaire */
.form-group {
    margin-bottom: 15px;
}

.google-signin-button, .signin-modal-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.375rem 0.75rem; /* Ajustez au besoin pour correspondre à votre design */
}

/* Icône dans le bouton Google pour un alignement correct */
.google-signin-button svg {
    margin-right: 10px;
}