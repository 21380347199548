:root {
  --bg-color: #2d2d2d;
  --text-color: #ffffff;
  --accent-color: #d4af37;
  --highlight-color: #ffcccb;
  --shadow-color: rgba(0, 0, 0, 0.4);
  --hover-shadow: rgba(0, 0, 0, 0.5);
}

/* ************************************ */
/* ***** Section globale (Biography) *** */
/* ************************************ */
.biography-container {
  background: linear-gradient(135deg, #1f2937 0%, #2d3748 60%, #1f2937 100%) !important;
  color: var(--text-color) !important;
  box-shadow: 0 8px 15px var(--shadow-color) !important;
  font-family: 'Playfair Display', serif !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  gap: 30px !important;
  padding: 120px 20px 40px !important;
  width: 100% !important;
  margin: 0 auto !important;
  transition: background 0.5s ease, box-shadow 0.3s ease !important;
}

/* Titre de la biographie */
.biography-header {
  text-align: center !important;
  margin-bottom: 30px !important;
}

.biography-title {
  font-size: 3rem !important;
  font-weight: bold !important;
  color: var(--accent-color) !important;
  transition: color 0.3s ease !important;
}

/* Contenu principal (texte + image) */
.biography-content {
  display: flex !important;
  flex-direction: row-reverse !important;
  align-items: center !important;
  gap: 30px !important;
  width: 100% !important;
  max-width: 1200px !important;
}

/* Image */
.biography-image-container {
  flex: 1 !important;
  text-align: center !important;
  position: relative !important;
  overflow: hidden !important;
}

/* Overlay sombre sur l’image au hover */
.biography-image-container::after {
  content: '' !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.2) !important;
  transition: background 0.3s ease !important;
}

.biography-image-container:hover::after {
  background: rgba(0, 0, 0, 0.4) !important;
}

/* Style de l’image */
.biography-image {
  max-width: 90% !important;
  height: auto !important;
  border-radius: 12px !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3) !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
}

.biography-image:hover {
  transform: scale(1.05) !important;
  box-shadow: 0 10px 20px var(--hover-shadow) !important;
}

/* Texte à côté de l’image */
.biography-text {
  flex: 2 !important;
  font-size: 1.2rem !important;
  line-height: 1.8 !important;
  text-align: justify !important;
  color: var(--text-color) !important;
}

.biography-text p {
  margin-bottom: 20px !important;
}

.biography-text em {
  font-style: italic !important;
  color: var(--highlight-color) !important;
}

/* *************************************** */
/* ***** Timeline (Frise chronologique) *** */
/* *************************************** */
.biography-timeline {
  position: relative !important;
  padding: 40px 0 !important;
  width: 100% !important;
  max-width: 1200px !important;
  margin: 0 auto !important;
  margin-top: 30px !important; /* Petite marge au-dessus de la frise */
}

/* Ligne verticale centrale dorée */
.biography-timeline::before {
  content: '' !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 50% !important;
  width: 4px !important;
  background: var(--accent-color) !important;
  transform: translateX(-50%) !important;
  opacity: 0.4 !important;
}

/* Cartes (timeline-item) */
.timeline-item {
  position: relative !important;
  width: 45% !important;
  padding: 20px !important;
  background: rgba(0, 0, 0, 0.5) !important;
  border-radius: 12px !important;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3) !important;
  margin-bottom: 40px !important;

  /* On les rend invisibles avant Intersection Observer */
  opacity: 0;
}

/* Position alternée gauche-droite */
.timeline-item:nth-child(odd) {
  left: 0 !important;
  text-align: right !important;
  transform: translateX(-50px);
}
.timeline-item:nth-child(even) {
  left: 55% !important;
  text-align: left !important;
  transform: translateX(50px);
}

/* Marqueurs circulaires (dorés) */
.timeline-item::before {
  content: '' !important;
  position: absolute !important;
  top: 20px !important;
  width: 16px !important;
  height: 16px !important;
  background: var(--accent-color) !important;
  border: 4px solid var(--bg-color) !important; 
  border-radius: 50% !important;
  z-index: 1 !important;
}

.timeline-item:nth-child(odd)::before {
  right: -8px !important;
}
.timeline-item:nth-child(even)::before {
  left: -8px !important;
}

/* Au moment où l’item devient visible (classe .visible),
   on lance l’animation de fade-in latérale */
.timeline-item.visible:nth-child(odd) {
  animation: fadeInFromLeft 0.8s forwards;
}
.timeline-item.visible:nth-child(even) {
  animation: fadeInFromRight 0.8s forwards;
}

/* Animations clés */
@keyframes fadeInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromRight {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Titre de l’étape de la timeline */
.timeline-item h2 {
  color: var(--accent-color) !important;
  margin-bottom: 10px !important;
  font-family: 'Playfair Display', serif !important;
  font-size: 1.4rem !important;
}

/* ************************************** */
/* ***** Responsive ********************* */
/* ************************************** */
@media (max-width: 768px) {
  /* Section principale : on empile image et texte */
  .biography-content {
    flex-direction: column !important;
    align-items: center !important;
    gap: 20px !important;
    padding: 0 15px !important;
  }

  /* Image un peu plus petite */
  .biography-image {
    max-width: 85% !important;
    border-radius: 8px !important;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2) !important;
  }

  /* Timeline : espacement */
  .biography-timeline {
    padding: 30px 15px !important;
    margin: 0 auto !important;
    margin-top: 30px !important;
  }

  /* Les items passent en affichage vertical */
  .timeline-item {
    width: 100% !important;
    text-align: left !important;
    padding: 15px 20px !important;
    margin-bottom: 30px !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
    left: 0 !important; /* On neutralise la position horizontale */
  }

  /* La ligne dorée se déplace sur la gauche */
  .biography-timeline::before {
    left: 20px !important;
    width: 3px !important;
    opacity: 0.5 !important;
  }

  /* Marqueur (point doré) au milieu vertical de chaque bloc */
  .timeline-item::before {
    left: 10px !important;
    top: 50% !important;
    width: 12px !important;
    height: 12px !important;
    background: var(--accent-color) !important;
    border: 2px solid var(--bg-color) !important;
    transform: translateY(-50%) !important;
  }

  /* Titre légèrement décalé (pour éviter le point) */
  .timeline-item h2 {
    font-size: 1.4rem !important;
    margin-left: 30px !important;
  }

  .timeline-item p {
    font-size: 1rem !important;
    line-height: 1.6 !important;
    margin-left: 30px !important;
  }

  /* Sur tablette, on unifie l’animation : fadeInFromLeft */
  .timeline-item.visible:nth-child(odd),
  .timeline-item.visible:nth-child(even) {
    animation: fadeInFromLeft 0.8s forwards !important;
  }
}

@media (max-width: 480px) {
  /* Container ajusté pour petits écrans */
  .biography-container {
    padding: 50px 10px 20px !important;
  }

  .biography-timeline {
    padding: 20px 10px !important;
    margin-top: 20px !important;
  }

  /* Taille réduite des items */
  .timeline-item {
    padding: 10px 15px !important;
    margin-bottom: 25px !important;
  }

  /* Marqueur réduit */
  .timeline-item::before {
    left: 8px !important;
    width: 10px !important;
    height: 10px !important;
  }
  .biography-timeline::before {
    left: 8px !important;
    width: 2px !important;
  }

  /* Titre et texte plus petits */
  .timeline-item h2 {
    font-size: 1.2rem !important;
    margin-left: 25px !important;
  }

  .timeline-item p {
    font-size: 0.9rem !important;
    line-height: 1.5 !important;
    margin-left: 25px !important;
  }

  /* Titre principal plus petit */
  .biography-title {
    font-size: 1.2rem !important;
    margin-bottom: 20px !important;
  }

  .biography-text {
    flex: 2 !important;
    font-size: 0.9rem !important;
    line-height: 1.8 !important;
    text-align: justify !important;
    color: var(--text-color) !important;
  }

  /* Sur très petit écran, on neutralise l'alternance gauche-droite */
  .timeline-item:nth-child(even) {
    left: 0 !important;
  }
}
