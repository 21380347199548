:root {
  --bg-gradient: linear-gradient(135deg, #1f2937 0%, #2d3748 60%, #1f2937 100%) !important;
  --text-color: #ffffff !important;
  --gold-color: #d4af37 !important;
  --shadow-color: rgba(0, 0, 0, 0.3) !important;
  --light-gold: #e8c674 !important;
  --hover-gold: #b39565 !important;
  --card-bg: #2d3748 !important;
}

/* Conteneur principal */
.featured-artist-container {
  background: var(--bg-gradient) !important;
  color: var(--text-color) !important;
  padding: 60px 2rem !important;
  text-align: center !important;
  border-bottom: 2px solid #d4af37 !important; /* Couleur dorée pour correspondre aux accents du design */
}

.featured-artist-title {
  color: var(--gold-color) !important;
  font-family: 'Playfair Display', serif !important;
  margin-bottom: 20px !important;
  font-size: 2.5rem !important;
  position: relative !important;
}

.featured-artist-title::after {
  content: '' !important;
  display: block !important;
  width: 80px !important;
  height: 3px !important;
  background: var(--gold-color) !important;
  margin: 10px auto 0 !important;
  border-radius: 2px !important;
}

.featured-artist-text {
  margin-bottom: 40px !important;
  font-family: 'Lora', serif !important;
  font-size: 1.2rem !important;
  opacity: 0.8 !important;
  max-width: 70% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

/* Carousel */
.featured-artist-carousel-item {
  display: flex !important;
  justify-content: center !important;
  gap: 2rem !important;
  padding: 1rem !important;
}

.carousel-control-prev,
.carousel-control-next {
  filter: invert(1) !important;
  opacity: 0.8 !important;
  transition: opacity 0.3s ease !important;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  opacity: 1 !important;
}

/* Carte d'artiste */
.featured-artist-card {
  width: 280px !important;
  border: none !important;
  background: var(--card-bg) !important;
  border-radius: 12px !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
  margin: 1rem !important;
  padding: 1rem !important;
  box-shadow: 0 4px 8px var(--shadow-color) !important;
}

.featured-artist-card:hover {
  transform: translateY(-5px) !important;
  box-shadow: 0 8px 16px var(--shadow-color) !important;
}

.featured-artist-image {
  border-radius: 50% !important;
  border: 5px solid var(--gold-color) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  width: 150px !important;
  height: 150px !important;
  object-fit: cover !important;
  margin: 0 auto 1rem auto !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
}

.featured-artist-image:hover {
  transform: scale(1.1) !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4) !important;
}

.featured-artist-card .card-title {
  color: var(--gold-color) !important;
  font-family: 'Playfair Display', serif !important;
  text-align: center !important;
  margin-top: 0.5rem !important;
  font-size: 1.5rem !important;
  font-weight: bold !important;
}

.featured-artist-card .card-text {
  font-size: 1rem !important;
  margin-bottom: 1rem !important;
  color: var(--text-color) !important; /* Texte blanc pour contraste */
  font-family: 'Lora', serif !important;
  line-height: 1.6 !important;
  text-align: justify !important; /* Améliore la lisibilité */
}

/* Liens sociaux */
.featured-artist-social-links {
  display: flex !important;
  justify-content: center !important;
  gap: 1rem !important;
  margin-top: 0.5rem !important;
}

.featured-artist-social-icon {
  color: var(--gold-color) !important;
  font-size: 1.5rem !important;
  transition: color 0.3s ease, transform 0.3s ease !important;
}

.featured-artist-social-icon:hover {
  color: var(--light-gold) !important;
  transform: scale(1.2) !important;
}

/* Bouton "En savoir plus" */
.featured-artist-details-button {
  font-size: 1rem !important;
  padding: 0.7rem 1.5rem !important;
  border-radius: 5px !important;
  border: 2px solid var(--gold-color) !important;
  background: var(--bg-gradient) !important;
  color: var(--gold-color) !important;
  font-family: 'Lora', serif !important;
  font-weight: bold !important;
  box-shadow: 0 4px 8px var(--shadow-color) !important;
  transition: background 0.3s ease, color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease !important;
}

.featured-artist-details-button:hover {
  background: var(--gold-color) !important;
  color: var(--bg-gradient) !important;
  transform: translateY(-3px) !important;
  box-shadow: 0 6px 12px var(--shadow-color) !important;
}

.featured-artist-details-button:focus {
  outline: none !important;
  box-shadow: 0 0 10px var(--gold-color) !important;
}
.featured-artist-button {
  font-size: 1.1rem !important; /* Taille légèrement plus grande pour attirer l'attention */
  padding: 0.8rem 2rem !important; /* Espacement confortable */
  border-radius: 6px !important; /* Coins légèrement arrondis */
  border: 2px solid var(--gold-color) !important; /* Bordure dorée pour contraste */
  background: var(--gold-color) !important; /* Fond doré */
  color: var(--bg-gradient) !important; /* Texte avec le dégradé pour un contraste inversé */
  font-family: 'Playfair Display', serif !important; /* Style de police élégant */
  font-weight: bold !important; /* Texte mis en avant */
  text-transform: uppercase !important; /* Texte en majuscules pour plus de clarté */
  box-shadow: 0 4px 10px var(--shadow-color) !important; /* Ombre pour un effet de profondeur */
  transition: all 0.3s ease !important; /* Transition fluide pour interactions */
}

.featured-artist-button:hover {
  background: var(--hover-gold) !important; /* Fond doré plus clair au survol */
  color: var(--text-color) !important; /* Texte blanc pour contraste */
  transform: translateY(-3px) !important; /* Légère élévation au survol */
  box-shadow: 0 6px 15px var(--shadow-color) !important; /* Ombre renforcée */
}

.featured-artist-button:focus {
  outline: none !important; /* Retire le contour par défaut */
  box-shadow: 0 0 10px var(--gold-color) !important; /* Lueur dorée au focus */
}

.featured-artist-button:active {
  background: var(--light-gold) !important; /* Fond doré plus clair lorsqu'il est pressé */
  transform: translateY(1px) !important; /* Légère enfoncement pour effet cliquable */
  box-shadow: 0 3px 8px var(--shadow-color) !important; /* Réduction de l'ombre */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .featured-artist-button {
    font-size: 1rem !important; /* Ajustement pour petits écrans */
    padding: 0.7rem 1.5rem !important;
  }
}

@media (max-width: 480px) {
  .featured-artist-button {
    font-size: 0.9rem !important;
    padding: 0.6rem 1.2rem !important;
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .featured-artist-card {
    width: 90% !important;
    margin: 1rem auto !important;
  }

  .featured-artist-container {
    padding: 20px 0 !important;
  }

  .featured-artist-carousel-item {
    flex-direction: column !important;
    align-items: center !important;
  }

  .featured-artist-carousel-item > div {
    margin-bottom: 2rem !important;
  }
}

@media (max-width: 480px) {
  .featured-artist-title {
    font-size: 2rem !important;
  }

  .featured-artist-text {
    font-size: 1rem !important;
    margin-bottom: 20px !important;
  }

  .featured-artist-button {
    font-size: 0.9rem !important;
    padding: 0.6rem 1.2rem !important;
  }
}
