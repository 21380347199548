/* src/components/Profile.css */
.card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
    background-image: linear-gradient(to right, #330000, #0d0d0d);
    color: #ffffff;
}

.card-body {
    padding: 20px;
}

.form-label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    text-align: left;
}

.items-center {
    color: #ffffff;
}

.form-control {
    background-color: #2b2b2b;
    border: 1px solid #660000;
    color: #ffffff;
}

.form-control:focus {
    background-color: #3c3c3c;
    border-color: #ffcccb;
    color: #ffffff;
    box-shadow: none;
}

.btn {
    background-color: #660000;
    border: none;
}

.btn:hover {
    background-color: #ffcccb;
    color: #660000;
    border: none;
}

.alert-danger {
    background-color: #660000;
    color: #ffffff;
    border: none;
}

.alert-success {
    background-color: #4BB543;
    color: #ffffff;
    border: none;
}

.form-control:hover,
.btn:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
}

.fa-user-edit,
.fa-lock {
    margin-right: 10px;
}

.card-body > h2 {
    text-align: center;
    color: #ffcccb;
    margin-bottom: 20px;
}

.card-body hr {
    border-top: 1px solid #660000;
}

@media (max-width: 768px) {
    .card {
        margin: 10px;
    }
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .form-label-icon {
    display: flex;
    align-items: center; /* Centre les éléments verticalement */
    color: #ffffff;
  }

  .fa-user-edit, .fa-lock {
    margin-right: 0.5rem; /* Espacement entre l'icône et le texte */
  }

  @media (min-width: 768px) { /* Pour les écrans de taille moyenne et supérieure */
    .card {
      max-width: 800px; /* Ajustez cette valeur selon vos besoins */
    }
  }